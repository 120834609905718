import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventsTypes } from '../../core/enums/events-types.enum';
import { MessageDirection, PhoneTranscriptionPopupData } from '../../types/phone-conversations.model';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { PropertyManagementFeature } from '../../types/property-management-feature';

@Component({
  selector: 'app-phone-conversation-popup',
  standalone: true,
  templateUrl: './phone-conversation-popup.component.html',
  imports: [DatePipe, MatRippleModule, NgForOf, NgIf],
  styleUrls: ['./phone-conversation-popup.component.scss'],
})
export class PhoneConversationPopupComponent {
  public messageDirection = MessageDirection;
  public eventsTypes = EventsTypes;
  public PropertyManagementFeature = PropertyManagementFeature;

  constructor(
    public dialogRef: MatDialogRef<PhoneConversationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhoneTranscriptionPopupData
  ) {}
}
