import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { AppService } from './app.service';

interface ResponseTypeData {
  responseType?: any;
}
@Injectable()
export class HttpService {
  constructor(
    public http: HttpClient,
    private appService: AppService
  ) {}
  getToken() {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  private getHmacSignature(data: any): string {
    const rawBody = JSON.stringify(data);
    return CryptoJS.HmacSHA256(rawBody, this.appService.ENV_CONFIG.HMAC_VALIDATION_SECRET).toString();
  }

  private getTargetHeaders(data: any) {
    const target = {
      'Content-Type': 'application/json',
      Authorization: this.getToken(),
    };
    if (data) target['X-Hmac'] = this.getHmacSignature(data);

    return target;
  }

  $request(options) {
    return new Promise((resolve, reject) => {
      fetch(options.url, {
        method: options.method,
        body: JSON.stringify(options.data),
        headers: Object.assign(
          {
            'content-type': 'application/json',
            Authorization: this.getToken(),
          },
          options.headers
        ),
      })
        .then((data) => {
          data.json().then((response) => {
            resolve(response);
          });
        })
        .catch((error) => reject(error));
    });
  }

  $httpGet(url, headers: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders(
        Object.assign(
          {
            'Content-Type': 'application/json',
            Authorization: this.getToken(),
          },
          headers
        )
      ),
    };
    const responseTypeData: ResponseTypeData = {};
    if (headers.responseType) {
      responseTypeData.responseType = headers.responseType;
    }
    return this.http.get(url, { ...httpOptions, ...responseTypeData });
  }

  $httpPost(url, data, headers: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders(Object.assign(this.getTargetHeaders(data), headers)),
    };
    const responseTypeData: ResponseTypeData = {};
    if (headers.responseType) {
      responseTypeData.responseType = headers.responseType;
    }
    return this.http.post(url, data, { ...httpOptions, ...responseTypeData });
  }

  $httpDelete(url, data, headers: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders(this.getTargetHeaders(data)),
      body: data,
    };
    const responseTypeData: ResponseTypeData = {};
    if (headers.responseType) {
      responseTypeData.responseType = headers.responseType;
    }
    return this.http.delete(url, { ...httpOptions, ...responseTypeData });
  }

  $httpPut(url, data, headers: any = {}) {
    const httpOptions = {
      headers: new HttpHeaders(Object.assign(this.getTargetHeaders(data), headers)),
    };
    const responseTypeData: ResponseTypeData = {};
    if (headers.responseType) {
      responseTypeData.responseType = headers.responseType;
    }
    return this.http.put(url, data, { ...httpOptions, ...responseTypeData });
  }
}
