import moment from 'moment-timezone';

const getTimeByTimeZone = (date = new Date(), timezone = 'America/Denver') => {
  return moment.tz(date, timezone).format('h:mm A');
};

/**
 * Checks if a given day number is past the current day of the month.
 * @param targetDay The day number to compare against the current day of the month.
 * @returns True if the target day is in the past, false otherwise.
 */
export function isDayOfMonthPassed(targetDay: number): boolean {
  // Get the current day of the month using Moment.js
  const currentDayOfMonth = moment().date();

  // Compare the current day of the month with the target day
  return currentDayOfMonth > targetDay;
}

const getUTCDateInYearMonthDayFormatAsString = (date: string, format = 'YYYY-MM-DD'): string =>
  moment.utc(date).format(format);

export default {
  getTimeByTimeZone,
  isDayOfMonthPassed,
  getUTCDateInYearMonthDayFormatAsString,
};
